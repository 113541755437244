import React from 'react';
import TopHeader from "../../components/topHeader.js";
import Global from "../../global"
import "./finance.scss";
import { ddlogo } from "../../assets/iconsList"
import { morningBg } from '../../assets/iconsList.js'



class finance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cmaintenance: [],
      monthMaintenance: "",
      paidMaintenance: "",
      yearMaintenance: "",
    }
  }
  componentDidMount() {
    this._isMounted = true;
    if(localStorage.getItem("startupdata")){
      let jData = JSON.parse(localStorage.getItem("startupdata"));
      if(jData) {
        this.setState({
          cmaintenance: jData.currentMonthMaintenance,
          monthMaintenance: JSON.parse(jData.monthMaintenance),
          paidMaintenance: JSON.parse(jData.paidMaintenance),
          yearMaintenance: JSON.parse(jData.yearMaintenance),
        })
      }

    }
    Global.dataCall('startupdata', (jData) => {
      this.setState({
        cmaintenance: jData.currentMonthMaintenance,
        monthMaintenance: JSON.parse(jData.monthMaintenance),
        paidMaintenance: JSON.parse(jData.paidMaintenance),
        yearMaintenance: JSON.parse(jData.yearMaintenance),
      })

    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  CurrencyFormat(d) {
    return
  }
  render() {
    const { monthMaintenance, paidMaintenance, yearMaintenance } = this.state;
    return (
      <div className="financeLandingPage">
        <TopHeader heading="Dollar Dreams Villas" routeTo="society" />
    
        <div className="flex-container">
          <div className="flexItem">
            <div className="societyText">Current Year Maintenance</div>
            <h5>{(yearMaintenance).toLocaleString("hi", { style: "currency", currency: "INR", minimumFractionDigits: 0 })}/-</h5>
          </div>
          <div className="flexItem">
            <div className="societyText"> Current Month Maintenance</div>
            <h5>{(monthMaintenance).toLocaleString("hi", { style: "currency", currency: "INR", minimumFractionDigits: 0 })}/-</h5>
          </div>
          <div className="flexItem">
            <div className="societyText"> Current Month Paid </div>
            <h5>{(paidMaintenance).toLocaleString("hi", { style: "currency", currency: "INR", minimumFractionDigits: 0 })}/-</h5>
          </div>
          <div className="flexItem">
            <div className="societyText"> Current Month Pending </div>
            <h5>{(monthMaintenance - paidMaintenance).toLocaleString("hi", { style: "currency", currency: "INR", minimumFractionDigits: 0 })}/-</h5>
          </div>

        </div>


      </div>
    )
  }
}
export default finance;