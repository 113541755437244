import React from 'react';
import { Row, Col, Modal, Button, Spinner } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { ArrowLeftCircle, Power } from 'react-bootstrap-icons';
import Auth from "../auth";
import Global, { WaitIcon } from "../global.js";
import { ddlogo } from "../assets/iconsList.js"
import Store from "../store";
import { mobileModel } from 'react-device-detect';

class TopHeader extends React.Component {
   constructor(props) {
      super(props);
      this.state = ({
         responseStatus: -1,
         tokenValue: localStorage.getItem("token"),
         logoutCall: false,
         logoutOption: false,
         setShow: false,
         deviceLogout1: false,
         deviceLogout2: false,
         currentDeviceLogout:false,
         device1: true,
         device2: true,
         items: []
      })
      this.priorLogout = this.priorLogout.bind(this);
      this.toggleClose = this.toggleClose.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.deviceLogout = this.deviceLogout.bind(this);
      this.BaseURL = Auth.BaseURL();
   }
   toggleClose = () => {
      let status = (this.state.logoutOption) ? false : true;
      this.setState({ logoutOption: status, setShow: true });
   }
   priorLogout = () => {
      this.setState({ logoutCall: true,currentDeviceLogout:true });
      Global.logout(() => {
         window.location.reload();
      });
   }
   handleClose = () => {

      this.setState({ setShow: false, logoutOption: false })
   }
   deviceLogout = (d, tokenVal) => {
      if (d === 1) {
         this.setState({ deviceLogout1: true })
      } else {
         this.setState({ deviceLogout2: true })
      }
      Global.otherDeviceLogout(tokenVal, (data) => {

         if (data.status > 0) {
            if (d === 1) {
               this.setState({ device1: false, deviceLogout1: false })
            } else {
               if (d === 2) {
                  this.setState({ device2: false, deviceLogout2: false })
               }
            }

         }
      });
   }
   componentDidMount() {
      this._isMounted = true;
      let getData = Store.getDeviceState();
      let self = this;


      if (!getData || JSON.stringify(getData) === '{}' || getData.length === 0) {

         if (localStorage.getItem("startupdata")) {
            let localObj = JSON.parse(localStorage.getItem("startupdata"));
            if (localObj.deviceList) {
               self.setState({
                  items: localObj.deviceList
               });
            }
         }


      } else {

         let profileData = getData;
         this.setState({
            items: profileData
         });

      }

   }


   componentWillUnmount() {
      this._isMounted = false;
   }


   render() {
      // const {responseStatus } = this.state;
      const { currentDeviceLogout,logoutCall, logoutOption, setShow, items, device2, device1, deviceLogout1, deviceLogout2 } = this.state;
      let ifRoute = (this.props.routeTo) ? <NavLink to={this.props.routeTo}><ArrowLeftCircle /></NavLink > : ''
      let logOutBtn = (this.props.routeTo) ? true : false;
      return (
         <Row className="topHeader">
            <Col xs={2} className="backtohomepage test">
               {ifRoute}
            </Col>

            <Col className="heading">
               <NavLink to="/welcome">
                  <span className="logo"> <img src={ddlogo()} alt="logo" /></span>
               </NavLink>
               {this.props.heading}</Col>
            {logOutBtn &&


               <Col>
                  <div className={logoutOption ? "logoutOptionsIcon open" : "logoutOptionsIcon"} onClick={this.toggleClose}>
                     <span></span>
                     <span></span>
                     <span></span>
                  </div>
                  {/* {logOutBtn} */}
               </Col>
            }
            {logoutCall &&
               <div className="wrapperLogout col-12">
                  <WaitIcon />
               </div>
            }

            <Modal show={setShow} onHide={this.handleClose} id="menuModal" className="zoom-in">
               <Modal.Header closeButton>
                  <Modal.Title>Menu</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <div className="menuHeader">Logout Options</div>

                  <div className="deviceContainer">
                     <h6>Current Device</h6>
                     <div className="diviceCol">
                        <span><strong>Name:</strong>{mobileModel}</span>
                        {!currentDeviceLogout &&
                        <Button onClick={this.priorLogout}><Power /> Logout</Button>
   }
                        {currentDeviceLogout &&
                                          <Spinner animation="border" role="status">
                                             <span className="sr-only">Loading...</span>
                                          </Spinner>
                                       }
                     </div>

                     <hr />
                     <h6>Other Devices</h6>
                     {items && items.length && items.map((val,index) => (
                        <div className="otherDeviceBlock" key={index}>

                           {!val.device2Name && !val.device3Name &&
                              <p>Your are not loggedin into any other device</p>
                           }
                           {val.device2Name &&
                              <div className="diviceCol">
                                 {device1 &&
                                    <div>
                                       <span><strong>Name:</strong>{val.device2Name}</span>
                                       {!deviceLogout1 &&
                                          <Button onClick={() => this.deviceLogout(1, val.device2token)}><Power /> Logout</Button>
                                       }
                                       {deviceLogout1 &&
                                          <Spinner animation="border" role="status">
                                             <span className="sr-only">Loading...</span>
                                          </Spinner>
                                       }
                                    </div>
                                 }
                              </div>

                           }
                           <hr />

                           {val.device3Name &&
                              <div className="diviceCol">
                                 {device2 &&
                                    <div>
                                       <span><strong>Name:</strong>{val.device3Name}</span>
                                       {!deviceLogout2 &&
                                          <Button onClick={() => this.deviceLogout(2, val.device3token)}><Power /> Logout</Button>
                                       }
                                       {deviceLogout2 &&
                                          <Spinner animation="border" role="status">
                                             <span className="sr-only">Loading...</span>
                                          </Spinner>
                                       }
                                    </div>
                                 }
                              </div>
                           }
                        </div>
                     ))}



                  </div>

               </Modal.Body>
            </Modal>

         </Row>
      )
   }
}
export default TopHeader;