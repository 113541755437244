import React from 'react';
import TopHeader from "../components/topHeader.js";
import { Table } from 'react-bootstrap';
import Global from "../global";
import Store from "../store";
class residentdirectory extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {

      items: [],

    };
    this.search = this.search.bind(this);

  }
  componentDidMount() {
    let self = this;
  
      if (localStorage.getItem("tenantdirectory")) {
        let localObj = JSON.parse(localStorage.getItem("tenantdirectory"));
        self.setState({
          items: localObj.data
        });
      }
      Global.dataCall('tenantdirectory', (data) => {

        let profileData = data.data;
        self.setState({
          items: profileData,

        });
      });

  }

  componentWillUnmount() {
    this._isMounted = false;

  }
  search = (event) => {
    event.preventDefault();
    // console.log("test----", event.target.value);
    var __FOUND = this.state.items.find(function (post) {
      if (post.firstname == event.target.value || post.middlename == event.target.value || post.lastname == event.target.value || post.umobile == event.target.value || post.uemail == event.target.value) {
        return true;
      }

    });
    if (__FOUND) {
      this.setState({
        items: [__FOUND]
      })
      // this.state.items = __FOUND;
    }

    console.log(__FOUND)
  }
  render() {

    const { items, searchTerm } = this.state;
    return (
      <div className="directory">
        <TopHeader heading="Tenant Directory" routeTo="/profile" />
        <div className="tableContainer topContSpace">
          <input className="d-none" type="text"
            placeholder="Start Typing"
            onChange={this.search} />


          <Table striped responsive size="sm">
            <thead>
              <tr>

                <th className="firstCol">Villa No</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {items && items.length > 0 && items.map((val) => {
                if (val.villatype === 'tenant') {

                  return (

                    <tr key={val.loginid}>
                      <td className="firstCol">{val.villa}</td>
                      <td>{val.firstname} {val.middlename} {val.lastname}</td>
                      <td>{val.umobile}</td>
                      <td>{val.uemail}</td>


                    </tr>
                  )
                }
              })}

            </tbody>
          </Table>
        </div>
      </div>
    )
  }
}
export default residentdirectory;