import React from 'react';
import ReactDOM from 'react-dom';
import "bootstrap/scss/bootstrap.scss";
import "bootstrap/dist/js/bootstrap";
import './index.css';
import './App.scss';
import reportWebVitals from './reportWebVitals';
import {Routes} from './Router.js';
import { HashRouter } from 'react-router-dom'
import Store from "./store";

ReactDOM.render(
  <HashRouter store={Store}>
      <Routes/>
  </HashRouter> ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
