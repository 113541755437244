  import React from 'react';
  import TopHeader from "../components/topHeader.js";
  import { Tabs, Tab, Row } from 'react-bootstrap';
  // import iconworker from "../assets/images/worker.png"
  
  class execommunity extends React.Component {
    render() {
      
      return (
        <div className="societyBoard">
          <TopHeader heading="Executive Community" routeTo="/society" />
          <div className="communityTabs localServicesTabs topContSpace">
            <Tabs defaultActiveKey={1} className="localTabs">
            <Tab eventKey={1} title="2018-Till Date">
                  <div className="introBlock">
                    <div className="descriptionBlock">
                      <Row>
                        <h5>President</h5>
                        <div className="label">V.Pandu Ranga Rao</div>
                        <div className="labelValue">8790856576</div>
                        <div className="labelValue">Villa No. 29</div>
                      </Row>
                      <Row>
                        <h5>Gen Secretary</h5>
                        <div className="label">Amendar</div>
                        <div className="labelValue">9676466554</div>
                        <div className="labelValue">Villa No. 44</div>
                      </Row>
                      <Row>
                        <h5>Treasurer</h5>
                        <div className="label">S.Prasad</div>
                        <div className="labelValue">8790856576</div>
                        <div className="labelValue">Villa No. 23</div>
                      </Row>
                     </div>
                  </div>
              </Tab>
              <Tab eventKey={2} title="2016-2018">
                  <div className="introBlock">
                  
                    <div className="descriptionBlock">
                      <Row>
                        <h5>President</h5>
                        <div className="label">T.V.Nahara Hari Reddy</div>
                        <div className="labelValue">9676466554</div>
                        <div className="labelValue">Villa No. 32</div>
                      </Row>
                      <Row>
                        <h5>Gen Secretary</h5>
                        <div className="label">Manish Shukla</div>
                        <div className="labelValue">7032918106</div>
                        <div className="labelValue">Villa No. 39</div>
                      </Row>
                      <Row>
                        <h5>Treasurer</h5>
                        <div className="label">M.S.Madhusudhan</div>
                        <div className="labelValue">9177401091</div>
                        <div className="labelValue">Villa No. 27</div>
                      </Row>
                     </div>
                  </div>
              </Tab>
              <Tab eventKey={3} title="2009-2016">
                  <div className="introBlock">
                  
                    <div className="descriptionBlock">
                      <Row>
                        <h5>President</h5>
                        <div className="label">S.K.R.S.Murthy</div>
                        <div className="labelValue">9849471671</div>
                        <div className="labelValue">Villa No. 46</div>
                      </Row>
                      <Row>
                        <h5>Gen Secretary</h5>
                        <div className="label">T.V.Nahara Hari Reddy</div>
                        <div className="labelValue">9676466554</div>
                        <div className="labelValue">Villa No. 32</div>
                      </Row>
                      <Row>
                        <h5>Treasurer</h5>
                        <div className="label">V.Pandu Ranga Rao</div>
                        <div className="labelValue">8790856576</div>
                        <div className="labelValue">Villa No. 29</div>
                      </Row>
                     </div>
                  </div>
              </Tab>
            </Tabs>
            </div>
        </div>
      )
    }
  }
  export default execommunity;