import React from 'react';
import "./setting.scss";
import { Tabs, Tab, Spinner, Button, Form, Alert } from 'react-bootstrap';
import TopHeader from "../../components/topHeader.js";
import axios from "axios";
import Store from "../../store"
import Auth from "../../auth";
import Global, { WaitIcon } from "../../global";
let BaseURL = Auth.BaseURL();
let localSerType = Global.localServices();

class profile extends React.Component {
   constructor(props) {
      super(props);
      this.state = ({
         localserviceData:[],
         changePwdStatus: [],
         localserviceStatus: [],
         editprofileStatus: [],
         pwdsubmitted: false,
         items: [],
         editprofileStatus: -1,
         ImageAvailable: false,
         presentation: false,
         ImgSizeError: false,
         file: '',
         imagePreviewUrl: "",
         uploadCompleted: false,
         editlocalserviceStatus : -1

      });
      this.changePassword = this.changePassword.bind(this);
      this.addlocalservice = this.addlocalservice.bind(this);
      this.updateLocalService = this.updateLocalService.bind(this);
      this.canvasId = React.createRef();
      this._handleSubmit = this._handleSubmit.bind(this)
      this._handleImageChange = this._handleImageChange.bind(this)

   }
   async _handleSubmit(e) {
      e.preventDefault()
      let res = await this.uploadFile(this.state.file);
      if (res.data.status == 'success') {
         localStorage.setItem("profilePic", res.data.url);
         this.props.history.replace("/profile");
      }


   }
   async uploadFile(file) {
      let formData = new FormData();
      formData.append('avatar', file);
      const headers = { 'content-type': 'multipart/form-data' };
      const loginid = localStorage.getItem("loginid");
      const token = localStorage.getItem("token");
      return await axios.post(BaseURL + "fileupload.php?loginid=" + loginid + "&token=" + token, formData, headers)
   }

   _handleImageChange(e) {
      e.preventDefault();
      this.setState({ uploadCompleted: true })
      let reader = new FileReader();
      let file = e.target.files[0];
      if (file) {
         // file.webkitRelativePath = 35;
         let sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
         if (sizeInMB > 1) {
            this.setState({
               ImgSizeError: true
            })
         } else {
            reader.onloadend = () => {
               this.setState({
                  file: file,
                  imagePreviewUrl: reader.result,
                  ImgSizeError: false,
                  uploadCompleted: false
               });
            }

            reader.readAsDataURL(file)
         }
      }

   }


   componentDidMount() {
      this._isMounted = true;
      let getData = Store.getState();
      let self = this;
      if (!getData || JSON.stringify(getData) === '{}' || getData.length == 0) {

         if (localStorage.getItem("startupdata")) {
            let localObj = JSON.parse(localStorage.getItem("startupdata"));
            if (localObj.profile) {
               self.setState({
                  items: localObj.profile.result.userdata,
               });
            }
         }

      } else {

         let profileData = getData.result.userdata;
         this.setState({
            items: profileData
         });
      }
      if (localStorage.getItem("localservices")) {
         let localObj = JSON.parse(localStorage.getItem("localservices"));
         if (localObj) {
            self.setState({
               localserviceData: localObj.userdata
            });
         }
      }else{
         Global.dataCall('localservices', (data) => {
            let profileData = data.userdata;
            self.setState({
               localserviceData: profileData
            });
          });
      }
   }
   componentWillUnmount() {
      this._isMounted = false;
   }
   updateLocalService =(event)=>{
      this.submitData(event, "editlocalservice");
   }
   addlocalservice = (event) => {
      this.submitData(event, "localservice");
   }

   editprofileSubmit = (event) => {
      this.submitData(event, "editprofile");
   }
   changePassword = (event) => {
      this.submitData(event, "changepassword");
   }
   submitData = (event, type) => {

      // return;
      event.preventDefault();
      this.setState({ pwdsubmitted: true })
      const formData = new FormData(event.target);
      var object = {};
      formData.forEach(function (value, key) {
         object[key] = value;
      });
      if (localStorage.getItem("loginid")) {
         object["loginid"] = localStorage.getItem("loginid");
      }
      

      const article = { data: object, submitType: type, token: localStorage.getItem("token") };
      const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
      let self = this;
      axios.post(BaseURL + "settings.php", article, headers)
         .then(response => {
            self.setState({ pwdsubmitted: false })
           
            if (response.data) {
            
               if (type === "changepassword") {
                  this.setState({ changePwdStatus: response.data });
                  if (response.data.status == 1) {
                     setTimeout(
                        () => Global.logout(),
                        3000
                     );
                  }
               } else if (type === "localservice") {
                  this.setState({ localserviceStatus: response.data });
                  if (response.data.status > 0) {
                     Global.dataCall('localservices', () => { });
                  }
               }
               else if (type === "editprofile") {
                  this.setState({ editprofileStatus: response.data.status });
                  if (response.data.status > 0) {
                     Global.dataCall('startupdata', () => { });
                  }
               }
               else if (type === "editlocalservice") {
                  this.setState({ editlocalserviceStatus: response.data.status });
                  if (response.data.status > 0) {
                     Global.dataCall('localservices', () => { });
                  }
               }

            }

         }).catch(error => {
            //cb(error)
            this.setState({ pwdsubmitted: false })
            console.log("Error", error)
         });
   }
   handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
   }

   render() {

      const {editlocalserviceStatus, localserviceData,editprofileStatus, items, changePwdStatus, localserviceStatus, pwdsubmitted, ImgSizeError, uploadCompleted } = this.state;
      let optionTemplate = localSerType.map(v => (<option key={v} value={v}>{v}</option>));

      let { imagePreviewUrl } = this.state;
      let $imagePreview = null;
      if (imagePreviewUrl) {
         $imagePreview = (<img src={imagePreviewUrl} />);
      } else {
         $imagePreview = (<div className="previewText">Please select an Image for Preview</div>);
      }
      let isServiceAdded = 0;
      return (
         <div className="societyBoard">

            <TopHeader heading="Setting" routeTo="welcome" />
            {pwdsubmitted && <WaitIcon />}
            <div className="settingBlock topContSpace localServicesTabs">
             
                     <h5 className="text-center">Change Password</h5>
                     <hr className="w-100 " />
                     {changePwdStatus["status"] == 0 &&
                        <Alert variant="danger">
                           <Alert.Heading>Error</Alert.Heading>
                           <p>{changePwdStatus["message"]}</p>

                        </Alert>
                     }
                     {changePwdStatus["status"] == 1 &&
                        <Alert variant="success">
                           <Alert.Heading>Success!!</Alert.Heading>
                           <p>{changePwdStatus["message"]} This window will automatically close. Please login again.</p>
                          
                        </Alert>
                     }
                     <div className="formBlock">
                        <Form onSubmit={this.changePassword}>
                           <Form.Group>
                              <Form.Label>Current Password</Form.Label>
                              <Form.Control name="oldpassword" type="password" placeholder="Password" />
                           </Form.Group>
                           <Form.Group>
                              <Form.Label>New Password</Form.Label>
                              <Form.Control name="newpassword" type="password" placeholder="Password" />
                           </Form.Group>
                           <Form.Group>
                              <Form.Label>ReType New Password</Form.Label>
                              <Form.Control type="password" placeholder="Password" />
                           </Form.Group>
                           <Form.Group>
                              <Button type="submit">Submit</Button>
                           </Form.Group>
                        </Form>
                     </div>
                
                  
                    
            </div>
         </div>
      )
   }
}
export default profile;