import React from "react";
import { Route, Switch, Redirect } from 'react-router-dom';
// import SwipeableRoutes from "react-swipeable-routes";
import Auth from "./auth";
import UserDashboard from './view/user/profile.js';
import Usermaintenance from './view/user/maintenance.js';
import UserquickUpdate from './view/user/quickupdate.js';
import Usersetting from './view/user/setting.js';
import Usersociety from './view/user/society.js';
import WelcomeDashboard from './view/welcomeUser.js';
import Finance from './view/admin/finance.js';
import FooterNav from './components/bottomNav/bottomnav.js';
import MaintenanceMonthWise from './subview/MaintenanceMonthWise.js';
import MeetingDetails from './subview/meetingDetails.js';
import Emergency from './subview/emergencyNumbers.js';
import Helpdesk from './subview/helpDesk.js';
import LocalServices from './subview/localServices.js';
import Amenities from './subview/amenities.js';
import ExeCommunity from './subview/community.js';
import Directory from './subview/directory.js';
import Tenantdirectory from './subview/tenantdirectory.js';
import WaterUsage from './subview/waterusage.js';
// import MyVisits from './subview/myvisits.js';
import Login from './login/login';


export const Routes = () => {

  let getAuth = Auth.getAuth();

  return (
  
    <div className="mainContainer">
   
      
      {getAuth &&
        <div className="routeBlock" >
           <Switch>
            <Route exact path="/" component={Finance} />
           {/* <SwipeableRoutes> */}
           <Route exact path="/welcome" component={WelcomeDashboard} />
            <Route exact path="/welcome" component={WelcomeDashboard} />
            <Route exact path="/profile" component={UserDashboard} />
            <Route exact path="/maintenance" component={Usermaintenance} />
            <Route exact path="/society" component={Usersociety} />
            <Route exact path="/quickupdate" component={UserquickUpdate} />
            <Route exact path="/setting" component={Usersetting} />
          {/* </SwipeableRoutes> */}
          </Switch>
          <Switch>

          {/* SubSections */}
          <Route exact path="/maintenance/MaintenanceMonthWise" component={MaintenanceMonthWise} />
          <Route exact path="/society/meetingdetails" component={MeetingDetails} />
          <Route exact path="/society/emergency" component={Emergency} />
          <Route exact path="/society/helpdesk" component={Helpdesk} />
          <Route exact path="/society/waterusage" component={WaterUsage} />
          <Route exact path="/society/amenities" component={Amenities} />
          <Route exact path="/society/execommunity" component={ExeCommunity} />
          <Route exact path="/maintenance/updateMaintenance" component={LocalServices} />
          <Route exact path="/profile/directory" component={Directory} />
          <Route exact path="/profile/tenantdirectory" component={Tenantdirectory} />
         
          {/* <Route exact path="/myvisits" component={MyVisits} /> */}
          {/* <Route exact path="/finance" component={Finance} /> */}
          {/* <Route exact path="/logout" component={Logout} /> */}
          {/* </SwipeableRoutes> */}
          </Switch>
          <FooterNav />
        </div>
      }
      {!getAuth && 
       <Switch>
       <Route exact path="/login" component={Login} />
       <Route exact path="/">
         <Redirect to="/login" />
       </Route>
       <Route path="*" component={Login} />
       </Switch>
      
      }
 
    </div>
   )
}
export default Routes;



