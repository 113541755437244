import React from 'react';
import TopHeader from "../components/topHeader.js";

class helpdesk extends React.Component {
  render() {
    return (
      <div className="societyBoard">
          <TopHeader heading="Help Desk" routeTo="/society" />
      </div>
    )
  }
}
export default helpdesk;