import './bottomNav.scss';
import React from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { PersonFill } from 'react-bootstrap-icons';
import { GearFill } from 'react-bootstrap-icons';
import { HouseFill } from 'react-bootstrap-icons';
import { FullscreenExit } from 'react-bootstrap-icons';
import Global, { WaitIcon } from "../../global";
class bottomNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = ({
      waitScreen: false
    })
    this.getData = this.getData.bind(this)
  }
  getData = (type) => {
    Global.eventBus.dispatch(type, { message: "Tab Clicked" });
  }
  render() {
    const { waitScreen } = this.state;
    return (
      <div className="bottomTabs">
        {waitScreen &&
          <WaitIcon />
        }

        <div className="mainTabsBottom">

          <NavLink onClick={() => this.getData("profile")} to="/profile">
            <PersonFill className="bottomNavIcon" />
            <span className="bottomNavText">Directory</span>
          </NavLink >

          <NavLink onClick={() => this.getData("maintenance")} to="/maintenance">
            <span className="bottomNavIcon currency">&#x20B9;</span>
            <span className="bottomNavText">Maintenance</span>
          </NavLink>

        
          <NavLink to="/setting">
            <GearFill className="bottomNavIcon" />
            <span className="bottomNavText">Setting</span>
          </NavLink>

        </div>
      </div>
    )
  }
}
export default bottomNav;
