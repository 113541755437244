import React from 'react';
import TopHeader from "../components/topHeader.js";
import { Button, Form, Alert } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { Calendar } from 'react-bootstrap-icons';
import "react-datepicker/dist/react-datepicker.css"
import axios from "axios";
import Auth from "../auth";
import Store from "../store";
import Global from "../global";
let BaseURL = Auth.BaseURL();


class amenities extends React.Component {
  
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      loginid: localStorage.getItem('loginid'),
      villa: localStorage.getItem('villa'),
      token: localStorage.getItem('token'),
      responseStatus: 0,
      alreadyBookedBy: 0,
      items: []
    };
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.submitBooking = this.submitBooking.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    let getData = Store.getHallBookingState();
    let self = this;
    console.log("Amenities landing page",getData)
    if (!getData || JSON.stringify(getData) === '{}' || getData.length == 0) {
      if (localStorage.getItem("viewHallBooking")) {
        let localObj = JSON.parse(localStorage.getItem("viewHallBooking"));
         self.setState({
              items: localObj.userdata
           });
        }
      console.log("data not available 1");
      Global.dataCall('viewHallBooking', (data) => {
        console.log("data not available", data);
        let profileData = data.userdata;
        self.setState({
          items: profileData
        });
      });
    } else {
      console.log("data available", getData)
      let profileData = getData.userdata;
      this.setState({
        items: profileData
      });

    }

  }


  componentWillUnmount() {
    this._isMounted = false;
  }



  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  handleStartDate(date) {
    this.setState({
      startDate: date
    })

  }
  handleEndDate(date) {
    this.setState({
      endDate: date
    })

  }
  submitBooking = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    var formObj = {};
    formData.forEach(function (value, key) {
      formObj[key] = value;
    });
    let splitFromDateTime = formObj['startDate'].split("-");
    formObj.bookingfrom = splitFromDateTime[0];
    formObj.timefrom = splitFromDateTime[1];

    let splittoDateTime = formObj['endDate'].split("-");
    formObj.bookingto = splittoDateTime[0];
    formObj.timeto = splittoDateTime[1];

    const article = { bookingdata: formObj, loginid: this.state.loginid, token: this.state.token, type: "hallbooking" };
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    // const Cors = { "mode": "no-cors" }

    axios.post(BaseURL + "userdb.php", article, headers)
      .then(response => {
        if (response.data.result.status) {
          this.setState({ responseStatus: response.data.result.status });
          if (response.data.result.status == 3) {
            this.setState({ alreadyBookedBy: response.data.result.bookedby });
          }
        }
      }).catch(error => {
        this.setState({ responseStatus: 2 })
        console.log("Error", error)
      });
  }
  render() {
    const { villa, responseStatus, alreadyBookedBy, items } = this.state;

    // const [startDate, setStartDate] = useState(new Date());
    return (
      <div className="societyBoard">
        <TopHeader heading="Amenities" routeTo="/society" />
        <div className="amenities topContSpace contP">
          <h5 className="text-center pt-4">Community Hall Booking</h5>
          <hr className="w-100" />
          {responseStatus == 1 &&
            <Alert variant="success">
              <Alert.Heading>Form Submitted</Alert.Heading>
              <p>Your Community Hall request is submitted successfully and pending for Admin approval.</p>
            </Alert>
          }

          {responseStatus == 2 &&
            <Alert variant="danger">
              <Alert.Heading>Something went wrong!!</Alert.Heading>
              <p>Please try again.</p>
            </Alert>
          }
          {responseStatus == 3 &&
            <Alert variant="danger">
              <Alert.Heading>Already booked!!</Alert.Heading>
              <p>Community hall already booked by <strong>Villa no {alreadyBookedBy}</strong> </p>
            </Alert>
          }
          <Form onSubmit={this.submitBooking}>
            <Form.Group controlId="formGridPassword">
              <Form.Label>Villa No.</Form.Label>
              <Form.Label>{villa}</Form.Label>
              <Form.Control onChange={this.handleChange} value={villa} type="hidden" name="vid" />
            </Form.Group>
            <Form.Group controlId="formGridPassword">
              <Form.Label>Booking Date </Form.Label>
            </Form.Group>
            <Form.Group controlId="formGridPassword">
              <Form.Label>
                <Form.Label>From Date <span className="mandatoryField">*</span> </Form.Label>
                <Calendar className="calendarIcon" />
                <DatePicker
                  required
                  selected={this.state.startDate}
                  onChange={this.handleStartDate}
                  name="startDate"

                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  timeCaption="time"
                  dateFormat="d MMMM yyyy - h:mm aa"
                  minDate={new Date()}
                />
              </Form.Label>
            </Form.Group>
            <Form.Group controlId="formGridPassword">
              <Form.Label>
                <Form.Label>To Date <span className="mandatoryField">*</span> </Form.Label>
                <Calendar className="calendarIcon" />
                <DatePicker
                  required
                  selected={this.state.endDate}
                  onChange={this.handleEndDate}
                  name="endDate"

                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  timeCaption="time"
                  dateFormat="d MMMM yyyy - h:mm aa"
                  minDate={new Date()}
                />
              </Form.Label>
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Booking Description <span className="mandatoryField">*</span></Form.Label>
              <Form.Control required name="description" as="textarea" rows={3} />
            </Form.Group>
            <Form.Group>
              <Button type="submit">Submit</Button>
            </Form.Group>
          </Form>
        </div>
        {items && 
        <div className="yourBookings ">
          <h5 className="cardHeaderBlock">Your Booking history</h5>
        <table className="table table-sm table-responsive table-striped table-bordered contP">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">From Date</th>
              <th scope="col">To Date</th>
              <th scope="col">Status</th>
              <th scope="col">Date</th>
              <th scope="col">Description</th>
            </tr>
          </thead>
          <tbody>
   
        {items && items.map(val =>
         <tr key={val.id}>
           <td>{val.id}</td>
           <td>{val.bookingfrom} - {val.timefrom}</td>
           <td>{val.bookingto} - {val.timeto}</td>
           <td>{val.bookstatus}</td>
           <td>{val.datetime}</td>
           <td>{val.description}</td>
         </tr>
             
            )}
            </tbody>
            </table>
        </div>
  }
      </div>
    )
  }
}
export default amenities;