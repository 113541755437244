import React from 'react';
import "./society.scss";
import TopHeader from "../../components/topHeader.js";
import { NavLink } from "react-router-dom";
import {iconkidExit,iconnotifyGate,iconMyVisits,iconfutureEntry} from '../../assets/iconsList.js'



class UserquickUpdate extends React.Component {
   render() {
      return (
         <div className="societyBlock topContSpace">
            <TopHeader heading="Gate" routeTo="welcome"/>
            <div className="flex-container">
               <div className="flexItem" >
               <img src={iconfutureEntry()} alt="meeting"/>
                  <div className="societyText">
                     Allow Future Entry
                  </div>
               </div>
               <div className="flexItem" >
                  <img src={iconkidExit()} alt="meeting"/>
                  <div className="societyText">
                    Allow Kids Exit
                  </div>
               </div>
                  
               <div className="flexItem" >
                  <img src={iconnotifyGate()} alt="meeting"/>
                  <div className="societyText">
                  Notify Gate
                  </div>
               </div>
               {/* <NavLink className="flexItem" to="/myvisits"> */}
               <div className="flexItem" >
                  <img src={iconMyVisits()} alt="meeting"/>
                  <div className="societyText">
                  My Visits
                  </div>
                  </div>
               {/* </NavLink> */}
            </div>
         </div>
      )
   }
}
export default UserquickUpdate;