import React,{useEffect} from 'react';
import TopHeader from "../components/topHeader.js";
import Store from "../store";
import Auth from "../auth";
import Global from "../global";
let BaseURL = Auth.BaseURL();



class waterusage extends React.Component {
  _isMounted = false;
  constructor(props) {
     super(props);
     this.state = {
        error: null,
        isLoaded: false,
        items: [],
        responseStatus:-1
     };

  }
  componentDidMount() {
    this._isMounted = true;
    let getData = Store.getwaterUsageState();
    let self = this;
    if(!getData || JSON.stringify(getData) === '{}' || getData.length == 0){
      if (localStorage.getItem("maxWaterUsage")) {
        let localObj = JSON.parse(localStorage.getItem("maxWaterUsage"));
       
           self.setState({
              items: localObj.userdata
           });
       
     }
        Global.dataCall('maxWaterUsage',(data) => {
          console.log("data not exist",data)
           let profileData = data.userdata;
           self.setState({
           items: profileData
           });
        });
     }else{
       let profileData = getData.userdata;
       this.setState({
        items: profileData
        });
      
     }
   
   }
  componentWillUnmount() {
     this._isMounted = false;
  }
  render() {
    const elements = ["July","Aug","Sep","Oct","Nov"];
    const {items} = this.state;
    return (
      <div className="societyBoard">
          <TopHeader heading="Max Water Usage" routeTo="/society" />
          <div className="waterUSageBlock homePageTopSpace p-0">
            <h5 className="contP">Maximum Water Usage List By Members <small>For the last six months</small></h5>
            <p className="contP">This list is not displayed to hurt the members' sentiments, but just as a wake up call to preserve it.
Water is precious because life could not exist without it. Life was born as a result of water, from plants to animals to humans.</p>
<table className="table table-responsive table-striped">
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Villa No</th>
                  <th>Unit Consumed</th>
                </tr>
              </thead>  
              <tbody>
              {items.map((val, index) => {
                if(val.vid){
                return(
               
                <tr>
                  <td>{Global.Months(val.month)} <small>{val.year}</small></td>
                  <td>{val.vid}</td>
                  <td>{val.maxwater}</td>
                </tr>
                )}
                }
                )
                }
              </tbody>  
            </table>
          </div>
      </div>
    )
  }
}
export default waterusage;