import React from 'react';
import TopHeader from "../components/topHeader.js";

class emergency extends React.Component {
  render() {
    return (
      <div className="societyBoard">
          <TopHeader heading="Emergency" routeTo="/society" />
          <div className="emergencyBlock topContSpace">
          <table className="table table-striped"> 
   <tbody>
      <tr> 
         <th>Emergency Contacts</th> 
         <th width="50">Contact Numbers</th> 
      </tr> 
   </tbody>
   <tbody> 
      <tr> 
         <td>Police</td> 
         <td>100</td> 
      </tr> 
      <tr> 
         <td>Fire</td> 
         <td>101</td> 
      </tr>
      <tr> 
         <td>Ambulance</td> 
         <td>102,108</td> 
      </tr>
      <tr> 
         <td>Women Helpline</td> 
         <td>181</td> 
      </tr>
      <tr> 
         <td>Blood Bank</td> 
         <td>040-24745243</td> 
      </tr> 
      <tr> 
         <td>Railway Enquiry</td> 
         <td>131/135</td> 
      </tr> 
      <tr> 
         <td>Electricity Complaint</td> 
         <td>1912</td> 
      </tr> 
      <tr> 
         <td>Child Line (For Children in distress)</td> 
         <td>1098</td> 
      </tr> 
      <tr> 
         <td>Crime Stopper</td> 
         <td>1090</td> 
      </tr> 
      <tr> 
         <td>Traffic Help</td> 
         <td>1073</td> 
      </tr> 
      <tr> 
         <td>Apollo Ambulance</td> 
         <td>1066</td> 
      </tr> 
         <tr> 
            <td>Osmania General Hospital</td> 
            <td>040-23538846, 040-24600146</td> 
         </tr>
         <tr> 
            <td>Gandhi Hospital</td> 
            <td>040-27505566</td> 
         </tr> 
         <tr> 
            <td>Government Chest Hospital</td> 
            <td>040-23814421/22/23/24</td> 
         </tr> 
         <tr> 
            <td>Government ENT Hospital</td> 
            <td>040-24740245/24742329&#8203;&#8203;&#8203;&#8203;</td> 
         </tr> 
   </tbody> 
</table>
          </div>
      </div>
    )
  }
}
export default emergency;