class Store {
  constructor() {
    this.state = {
      profile: [],
      maintenance: [],
      directory: [],
      waterUsage: [],
      localservices: [],
      hallBooking: [],
      favlist:[],
      deviceDetails:[]
    };
  }
  setState(db) {
    this.state.profile = db;
  }
  getState() {
    return this.state.profile;
  }  
  setDevice(db) {
    this.state.deviceDetails = db;
  }
  getDeviceState() {
    return this.state.deviceDetails;
  }
  setDirState(db) {
    this.state.directory = db;
  }
  getDirState() {
    return this.state.directory;
  }
  setMaintenanceState(db) {
    this.state.maintenance = db
  }
  getMaintenanceState() {
    return this.state.maintenance;
  }
  setwaterUsage(db) {
    this.state.waterUsage = db;
  }
  getwaterUsageState() {
    return this.state.waterUsage;
  }
  setlocalservices(db) {
    this.state.localservices = db;
  }
  getlocalservicesState() {
    return this.state.localservices;
  }
  setHallBooking(db) {
    this.state.hallBooking = db;
  }
  getHallBookingState() {
    return this.state.hallBooking;
  }
  setFav(db) {
    this.state.favlist = db;
  }
  getFavState() {
    return this.state.favlist;
  }
}

const store = new Store({});
export default store;