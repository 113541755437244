import React from 'react';
import "./society.scss";

import iconhelpdesk from "../../assets/images/helpdesk.png";
import TopHeader from "../../components/topHeader.js";
import { NavLink } from "react-router-dom";
// import Global from "../../global";
import {iconSociety,iconResidentDetails,meetingDetails,amenitiesIcon,iconMaxWaterUsage, iconNoticeBoard,iconEmergencyNumber,iconlocalService} from '../../assets/iconsList.js'


class Maintenance extends React.Component {
   constructor(props) {
      super(props);
      this.state = ({
        waitScreen: false
      })
     
    }
  
   render() {
      return (
         <div className="societyBlock">
            <TopHeader heading="Society" routeTo="welcome"/>
            <div className="flex-container topContSpace">
               <NavLink className="flexItem" to="/maintenance/MaintenanceMonthWise">
                  <img src={iconNoticeBoard()} alt="noticeboard" />
                  <div className="societyText">
                     Month Wise
                  </div>
               </NavLink>
               <NavLink className="flexItem" to="/maintenance/meetingdetails">
                  <img src={meetingDetails()} alt="meetingdetails" />
                  <div className="societyText">
                     Year Wise
                  </div>
               </NavLink>
               <NavLink className="flexItem" to="/maintenance/emergency">
                  <img src={iconEmergencyNumber()} alt="emergency" />
                  <div className="societyText">
                     Add Water Charges
                  </div>
               </NavLink>
               {/* <NavLink className="flexItem" to="/maintenance/helpdesk"> */}
               <div className="flexItem">
               <img src={iconhelpdesk} alt="helpdesk" />
                  <div className="societyText">
                     Add Monthly Maintenance
                  </div>
               </div>
                 
               {/* </NavLink> */}
               <NavLink className="flexItem" to="/maintenance/updateMaintenance">
                  <img src={iconlocalService()} alt="localservice" />
                  <div className="societyText">
                     Update Maintenance
                  </div>
               </NavLink>
               <NavLink className="flexItem" to="/maintenance/amenities">
                  <img src={amenitiesIcon()} alt="amenities" />
                  <div className="societyText">
                     Download Excel
                  </div>
               </NavLink>
               <NavLink className="flexItem" to="/maintenance/execommunity">
                  <img src={iconSociety()} alt="execommunity" />
                  <div className="societyText">
                     Community Hall Booking
                  </div>
               </NavLink>
               
            </div>
         </div>
      )
   }
}
export default Maintenance;