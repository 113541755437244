import React from 'react';
import "./society.scss";
import TopHeader from "../../components/topHeader.js";
import { NavLink } from "react-router-dom";

import {tenantlist,iconResidentDetails,iconlocalService} from '../../assets/iconsList.js'


class Directory extends React.Component {
   constructor(props) {
      super(props);
      this.state = ({
        waitScreen: false
      })
     
    }
  
   render() {
      return (
         <div className="societyBlock">
            <TopHeader heading="Directory" routeTo="profile"/>
            <div className="flex-container topContSpace">
               <NavLink className="flexItem" to="/profile/directory">
                  <img src={iconResidentDetails()} alt="directory" />
                  <div className="societyText">
                     Owners List
                  </div>
               </NavLink>
               <NavLink className="flexItem" to="/profile/tenantdirectory">
                  <img src={tenantlist()} alt="tenantdirectory" />
                  <div className="societyText">
                     Tenant List
                  </div>
               </NavLink>
               <NavLink className="flexItem" to="/profile/localservices">
                  <img src={iconlocalService()} alt="localservice" />
                  <div className="societyText">
                     Local Services
                  </div>
               </NavLink>

            </div>
         </div>
      )
   }
}
export default Directory;