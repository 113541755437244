class Auth {
    constructor() {
        this.authenticated = false;
    }
    login(cb) {
        console.log("AuthLogin");
        localStorage.setItem("isAuthenticated",true)
       
        cb();
    }

    logout(cb) {
        console.log("Auth Logout")
        localStorage.clear();
        cb();
    }

    getAuth() {
       let authenticated = (localStorage.getItem("isAuthenticated"))?true:false;
       return authenticated;
    }
    BaseURL(){
    // let url =(window.location.hostname === 'localhost')?"http://localhost/ddconnect/community/":"http://dollardreamsvillas.com/ddapp/php/community/";
      let url = "http://dollardreamsvillas.com/ddapp/php/community/";
        return url;
    }
}

export default new Auth();
