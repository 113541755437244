import React from "react";
import Auth from "../auth";
import './login.scss';
import axios from "axios";
import {PersonPlusFill,PersonCheckFill } from 'react-bootstrap-icons';
import { Container, Button, Form, Alert } from "react-bootstrap";
import TopHeader from "../components/topHeader.js";
import Global,{ WaitIcon} from "../global.js";
import { mobileModel } from 'react-device-detect';

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = ({
      redirect: false, username: '',
      password: '',
      submitted: false,
      values1: Array.apply(null, { length: 64 }).map(Number.call, Number),
      values: Array.from(Global.range(7, 65)),
      responseStatus: [],
      logoutStatus: false,
      createUsername: '', createPassword: '', confirmPassword: '',
      createLoginScreen: false,
      createAuth: '',
      defaultTabKey: "login",
      loginCreated:false,
      hidelogin:false,
      hideSignup:true,
      registrationError:false,
      loginError:false,
      villaExistError:false,
      villa :localStorage.getItem("villa"),
      loginid : localStorage.getItem("loginid"),
      ErrorMsg:""
     
   });
    this.response = [];
    this.BaseURL = Auth.BaseURL();
    this.submitLogin = this.submitLogin.bind(this);
    this.handleChange = this.handleChange.bind(this);
   

    this.hideReg = this.hideReg.bind(this);
    this.hidelogin = this.hidelogin.bind(this);
    this.verifyUserName = React.createRef();
    this.userVerificationStatus = 2;
   
    this.clearLocalstorage = this.clearLocalstorage.bind(this);
  

  }

  componentDidMount() {
    if (localStorage.getItem("loginid")) {
      this.setState({ 
        createLoginScreen: true, 
        hidelogin:true,
        hideSignup:false });
    }  
    if (localStorage.getItem("loginCreated")) { 
      this.setState({ loginCreated: true, hidelogin:false,
        hideSignup:true,loginError:false});
    }
    // if (!localStorage.getItem("villa")) { 
    //     localStorage.setItem("villa",7);
    // }
  }
  clearLocalstorage =()=>{
    localStorage.clear();
    this.setState({
      createLoginScreen:false
    })
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  submitLogin = () => {
    this.setState({ logoutStatus: true });
    this.AuthLogin(false)
  }
  submitLoginwithLogout = () => {
    this.setState({ logoutStatus: true });
    this.AuthLogin(true)
  }
  hideReg =()=>{
    localStorage.clear();
    this.setState({
      hidelogin:false,
      hideSignup:true,
      loginError:false,
      registrationError:false
    });
    window.scrollTo(0,0) 
  }
  hidelogin = ()=>{
  this.setState({
      hidelogin:true,
      hideSignup:false,
      loginError:false,
      registrationError:false
    });
    window.scrollTo(0,0) 
  }

 


  AuthLogin(type) {
    window.scrollTo(0,0) 
    const article = { username: this.state.username, password: this.state.password, forceLogout: type, tokendevice: mobileModel};
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    // const Cors = { "mode": "no-cors" }
    var self = this;
    axios.post(this.BaseURL + "adminlogin.php", article, headers)
      .then(response => {
        self.setState({ responseStatus: response.data.result, loginCreated:false,loginError:false });
        this.setState({ logoutStatus: false });
        if (response && response.data.result.status > 1) {
          Auth.login(() => {
            localStorage.setItem("token", response.data.result.token);
            localStorage.setItem("villa", response.data.result.villa);
            localStorage.setItem("loginid", response.data.result.loginid);
            if(response.data.result.status === 2){
              this.props.history.replace("/welcome");
           
            }
            
            window.location.reload();

          });
        }
      }).catch(error => { 
        this.setState({ logoutStatus: false,loginError:true });
        console.log("Error",error)
      });
  }


  render() {
    // const [key, setKey] = useState('signup');
    const {ErrorMsg,villa,loginid,villaExistError,loginError,registrationError,hidelogin,hideSignup, username, password, responseStatus, logoutStatus, createLoginScreen, createAuth,loginCreated } = this.state;
    let optionTemplate = this.state.values.map(v => (

        <option key={v} value={v}>{v}</option>
     
    )    
    );
  
    const regFrom = (hideSignup)?"registrationForm d-none":"registrationForm";
    const loginFrom = (hidelogin)?"loginForm d-none":"loginForm";

    return (

      <div className="App">
        <header className="App-header">
          <TopHeader heading="Dollar Dreams Villas" />
          <Container className="topContSpace">
            {logoutStatus &&
              <div className="wrapperLogout">
                <WaitIcon />
              </div>
            }
          
            {/* <Tabs className="loginTabs" activeKey={defaultTabKey} defaultActiveKey={defaultTabKey} id="uncontrolled-tab-example"> */}
                <div className={loginFrom}>
                  <h2>Login</h2>
                {responseStatus.status === 0 &&
                  <div className="alreadyLoggedin">
                    <Alert variant="success">
                      <Alert.Heading>Oh snap! You are already loggedin!</Alert.Heading>
                      <p>You are already loggedin into other 3 devices.</p>
                      <hr />
                      <p>Click <button onClick={this.submitLoginwithLogout} >here</button> to logout from other device and login into this device.</p>
                    </Alert>
                  </div>
                }
                {
                  responseStatus.status === 1 && !loginError &&
                  <Alert variant="danger">
                  <Alert.Heading>Error</Alert.Heading>
                  <p>{responseStatus.error}
                </p>
 
                </Alert>
                }
                {loginCreated && !loginError &&
                 <Alert variant="success">
                 <Alert.Heading>Account created !</Alert.Heading>
                 <p>Your account created successfully. Please login to your account.</p>
               </Alert>
               
                }
                 {
                  loginError && 
                  <Alert variant="danger">
                  <Alert.Heading>Something went wrong!!!</Alert.Heading>
                  <p> {ErrorMsg}</p>
 
                </Alert>
                }
                {responseStatus !== 0 &&
                  <Form>
                    <Form.Group>
                      {username && 
                      <Form.Label>UserName</Form.Label>
                      }
                      
                      <Form.Control type="text" value={username} onChange={this.handleChange} required name="username" placeholder="Enter Username" />
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                    <Form.Group>
                      {password &&
                       <Form.Label>Password</Form.Label>
                      }
                     
                      <Form.Control type="password" value={password} required onChange={this.handleChange} name="password" placeholder="Enter Password" />
                    </Form.Group>

                    <Button disabled={username === '' || password === ''} variant="primary" type="button" onClick={this.submitLogin}> Submit</Button>
                   
                  </Form>
                }
                </div>
           
           

          </Container>

        </header>
      </div>
    );
  }
}

export default Login;
