import React from 'react';
import TopHeader from "../components/topHeader.js";
import "./subview.scss";
import Global from "../global";

class MaintenanceMonthWise extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.getHeader = this.getHeader.bind(this);
    this.getRowsData = this.getRowsData.bind(this);
    this.getKeys = this.getKeys.bind(this);
  }
  getKeys = function () {
    if (this.state && this.state.items) {



      return Object.keys(this.state.items[0]);
    }

  }

  getHeader = function () {
    var keys = this.getKeys();
 
    if (keys) {

      return keys.map((key) => {
    
        return <th key={key}>{key}</th>
      })
    }

  }

  getRowsData = function () {
    if (this.state && this.state.items) {
      var items = this.state.items;
      var keys = this.getKeys();
      return items.map((row, index) => {
        return <tr key={index}><RenderRow key={index} data={row} keys={keys} /></tr>
      })
    }
  }
  componentDidMount() {
    this._isMounted = true;
    let self = this;

 
    Global.dataCall('maintenanceMonthWise', (data) => {
      console.log(data)
      let profileData = data.maintenanceObj;
      self.setState({
        items: profileData,

      });
    });

  }
  componentWillUnmount() {
    this._isMounted = false;
  }


  render() {

    return (
      <div className="noticeBoard">
        <TopHeader heading="Maintenance Monthwise" routeTo="/society" />
        <div className="boardBlock topContSpace">
          <div className="noticeContainer">
            {/* <h3>No latest Meeting update</h3> */}
            <table className="table adminTable responsive-table">
              <thead>
                {this.getHeader() &&
                  <tr>{this.getHeader()}</tr>
                }

              </thead>
              {this.getRowsData() &&
                <tbody>
                  {this.getRowsData()}
                </tbody>
              }
            </table>
          </div>



        </div>


      </div>
    )
  }
}
const RenderRow = (props) => {
  return props.keys.map((key, index) => {
    return <td key={props.data[key]}>{props.data[key]}</td>
  })
}
export default MaintenanceMonthWise;