import axios from "axios";
import Auth from "./auth";
import Store from './store';

let BaseURL = Auth.BaseURL();

let WaitIcon = () => {
  return (
    <div className="fullScreenWait">
      <div className="wrapper"></div>
      <div className="waitIcon col-12">
        <div className="loader"></div>
        <div className="loadingText">Please wait..</div>
      </div></div>
  )
}
class Global {
  eventBus = {
    on(event, callback) {
      document.addEventListener(event, (e) => callback(e.detail));
    },
    dispatch(event, data) {
      document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    remove(event, callback) {
      document.removeEventListener(event, callback);
    },
  };
  getData = (type) => {
    this.eventBus.dispatch(type, { message: "Tab Clicked" });
  }

  dataCall = (val, cb) => {
    //  Using this fpr Profile, maintenance, Resident, MAx water usage


    let activetab = val;
    const article = { villa: localStorage.getItem('villa'), loginid: localStorage.getItem('loginid'), token: localStorage.getItem('token'), type: activetab };
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
  
    if (activetab === "viewHallBooking") {
      article.vid = localStorage.getItem("villa");
    }
    let filename = 'adminstartup.php';
   
    axios.post(BaseURL + ""+filename, article, headers)
      .then(response => {
    
        if (response.data.result) {
console.log("activetab ==",activetab)
          switch (activetab) {
            case "startupdata":
            //   Store.setState(response.data.result.profile);
            break;
            // case "directory":
            //   Store.setDirState(response.data.result);
            //   break;
          }
          localStorage.setItem(activetab, JSON.stringify(response.data.result))
       
          cb(response.data.result)
        }

      }).catch(error => {
        //cb(error)
        console.log("Error", error)
      });
  }

  logout = (cb) => {
    const tokenValue = localStorage.getItem("token");
    const vidValue = localStorage.getItem("loginid");
    const article = { token: tokenValue, loginid: vidValue };
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const Cors = { "mode": "no-cors" }
    axios.post(BaseURL + "logout.php", article, headers, Cors)
      .then(response => {
        if (response.data.result.status === 1) {
          Auth.logout(() => {
            window.location.reload();
          });
        }
      }).catch(response => {
        //    cb(response,"error");
      });
  }
  otherDeviceLogout = (tokenval,cb) => {
    const tokenValue = tokenval;
    const vidValue = localStorage.getItem("loginid");
    const article = { token: tokenValue, loginid: vidValue };
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const Cors = { "mode": "no-cors" }
    axios.post(BaseURL + "logout.php", article, headers, Cors)
      .then(response => {
        if (response.data.result.status === 1) {
          this.dataCall('startupdata',() => {});
           
        }
        cb(response.data.result)
            }).catch(response => {
              cb(response.data.result)
        //    cb(response,"error");
      });
  }
  Months = (d) => {
    let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let selectedMonths = months[d - 1];
    return selectedMonths;
  }
  range = (start, stop, step = 1) => {
    return [...Array(stop - start).keys()]
      .filter(i => !(i % Math.round(step)))
      .map(v => start + v)
  }
  localServices = () => {
    let serviceType = ["Maid", "Plumber", "Electrician", "Plywood", "Grocery", "Painter", "Carpenter", "Mechanic", "Hospital", "Caterer"];
    return serviceType;
  }
  paymentStatus = () => {
    let serviceType = ["Received Online", "Pending","Received Cash"];
    return serviceType;
  } 
  filterOption = () => {
    let serviceType = ["All","Received Online", "Pending","Received Cash"];
    return serviceType;
  }
}
export default new Global();
export { WaitIcon }