import React from 'react';
import "./society.scss";

import iconhelpdesk from "../../assets/images/helpdesk.png";
import TopHeader from "../../components/topHeader.js";
import { NavLink } from "react-router-dom";
// import Global from "../../global";
import {iconSociety,iconResidentDetails,meetingDetails,amenitiesIcon,iconMaxWaterUsage, iconNoticeBoard,iconEmergencyNumber,iconlocalService} from '../../assets/iconsList.js'


class profile extends React.Component {
   constructor(props) {
      super(props);
      this.state = ({
        waitScreen: false
      })
     
    }
  
   render() {
      return (
         <div className="societyBlock">
            <TopHeader heading="Society" routeTo="welcome"/>
            <div className="flex-container topContSpace">
               <NavLink className="flexItem" to="/society/noticeboard">
                  <img src={iconNoticeBoard()} alt="noticeboard" />
                  <div className="societyText">
                     Notice Board
                  </div>
               </NavLink>
               <NavLink className="flexItem" to="/society/meetingdetails">
                  <img src={meetingDetails()} alt="meetingdetails" />
                  <div className="societyText">
                     Meeting Details
                  </div>
               </NavLink>
               <NavLink className="flexItem" to="/society/emergency">
                  <img src={iconEmergencyNumber()} alt="emergency" />
                  <div className="societyText">
                     Emergency Numbers
                  </div>
               </NavLink>
               {/* <NavLink className="flexItem" to="/society/helpdesk"> */}
               <div className="flexItem">
               <img src={iconhelpdesk} alt="helpdesk" />
                  <div className="societyText">
                     Helpdesk
                  </div>
               </div>
                 
               {/* </NavLink> */}
               <NavLink className="flexItem" to="/society/localservices">
                  <img src={iconlocalService()} alt="localservice" />
                  <div className="societyText">
                     Local Services
                  </div>
               </NavLink>
               <NavLink className="flexItem" to="/society/amenities">
                  <img src={amenitiesIcon()} alt="amenities" />
                  <div className="societyText">
                     Amenities
                  </div>
               </NavLink>
               <NavLink className="flexItem" to="/society/execommunity">
                  <img src={iconSociety()} alt="execommunity" />
                  <div className="societyText">
                     Executive committee
                  </div>
               </NavLink>
               <NavLink   className="flexItem" to="/society/directory">
                  <img src={iconResidentDetails()} alt="directory" />
                  <div className="societyText">
                     Resident Directory
                  </div>
               </NavLink>
               <NavLink className="flexItem" to="/society/waterusage">
                  <img src={iconMaxWaterUsage()} alt="society" />
                  <div className="societyText">
                     Max water usage
                  </div>
               </NavLink>
            </div>
         </div>
      )
   }
}
export default profile;