import React from 'react';
import TopHeader from "../components/topHeader.js";
import { Table, Form, Button, Spinner,Alert } from 'react-bootstrap';
import axios from "axios";
import "./subview.scss";
import Auth from "../auth";
import Global from "../global";
let localSerType = Global.paymentStatus();
let filterOption = Global.filterOption();
let BaseURL = Auth.BaseURL();
const current = new Date();
current.setMonth(current.getMonth()-1);
const previousMonth = current.toLocaleString('default', { month: 'long' }); 


class Localservices extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = ({
      items: [],
      selectedTab: "Maid",
      selectedRow: [],
      statusClass : 'txtgreen',
      filterRow:'All'
    });
    this.updatePayment = this.updatePayment.bind(this);
    this.filterOption = this.filterOption.bind(this);
  }

  DOM = (id) => {
    return document.getElementById(id);
  }
  updatePayment = event => {

    event.preventDefault();
    const formData = new FormData(event.target);
    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });

    if (this.DOM("button" + object.vid)) {
      this.DOM("button" + object.vid).style.display = "none"
    }
    if (this.DOM("spinner" + object.vid)) {
      this.DOM("spinner" + object.vid).style.display = "block"
    }

    const article = { data: object, type: "updateMaintenancce", loginid: localStorage.getItem("loginid"), token: localStorage.getItem("token") };
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

    axios.post(BaseURL + "adminstartup.php", article, headers)
      .then(response => {
      console.log(response)
        let sClass = (response.data.result.status > 0)?'txtgreen':"txtred";
        this.setState({
          statusClass:sClass
        })
        this.DOM("button" + object.vid).style.display = "block"
        this.DOM("spinner" + object.vid).style.display = "none"

        if (this.DOM("formStatus" + object.vid)) {
          this.DOM("formStatus" + object.vid).style.visibility = "visible"
          this.DOM("formStatus" + object.vid).innerHTML = response.data.result.message
        }
      }).catch(error => {
        console.log("error", error);
        this.DOM("button" + object.vid).style.display = "block"
        this.DOM("spinner" + object.vid).style.display = "none"
        if (this.DOM("formStatus" + object.vid)) {
          this.DOM("formStatus" + object.vid).style.visibility = "visible"
          this.DOM("formStatus" + object.vid).innerHTML = error
        }
      })


  }

  componentDidMount() {
    this._isMounted = true;
    if (localStorage.getItem("startupdata")) {
      let jData = JSON.parse(localStorage.getItem("startupdata"));
      if (jData) {
        this.setState({
          items: jData.currentMonthMaintenance[0],
          monthMaintenance: jData.monthMaintenance,
        })
      }
    }
    let self = this;
    Global.dataCall('startupdata', (jData) => {

     
      self.setState({
        items: jData.currentMonthMaintenance[0],
        monthMaintenance: jData.monthMaintenance,

      });
    });

  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  filterOption=(event)=>{
    this.setState({
      filterRow:event.target.value
    })
  }

  render() {
    const { items, statusClass,filterRow} = this.state;
    let optionTemplate = localSerType.map(v => (<option key={v} value={v}>{v}</option>));
    let optionFilter = filterOption.map(v => (<option key={v} value={v}>{v}</option>));
    return (
      <div className="directory ">

        <TopHeader heading="Update Maintenance" routeTo="/society" />
        <div className="tableContainer container updateMaintenance">
        <Alert variant="info">
                  <Alert.Heading>{previousMonth} Month Maintenance</Alert.Heading>
               
 
                </Alert>
                <Form.Group>

<Form.Control as="select" className="SearchFilter" onChange={this.filterOption} label="Filters" custom defaultValue={filterRow} >
  {optionFilter}
</Form.Control>

</Form.Group>

          <Table striped responsive size="sm" className={filterRow == 'Pending'?'showPending':(filterRow == 'All')?"showAll":(filterRow == 'Received Cash')?"showCash":"showPaid"}>
            <thead>
              <tr>

                <th>Villa</th>
                <th>Total</th>
                <th>Payment</th>
                <th>Action</th>

              </tr>
            </thead>
            <tbody>

        
              {items && items.map(val =>
                <tr key={val.vid} className={val.paymentstatus.toLowerCase().replace(" ","")}>

                  <td>{val.vid}</td>
                  <td>{val.nearestroundoff}</td>
                  <td className={val.paymentstatus.toLowerCase() == 'pending'?'txtred':'txtgreen'}>{val.paymentstatus}</td>
                  <td>
                    <Form className="updateForm" onSubmit={this.updatePayment}>
                      <input type="hidden" name="vid" value={val.vid} />
                      <input type="hidden" name="month" value={val.month} />
                      <input type="hidden" name="year" value={val.year} />
                      <Form.Group>
                        <Form.Control as="select" name="paymentstatus" custom defaultValue={val.paymentstatus} >
                          {optionTemplate}
                        </Form.Control>

                      </Form.Group>
                      <Form.Control type="text" defaultValue={val.paymentremark} name="paymentremark" placeholder="Payment Remark" />

                      <Button variant="primary" id={"button" + val.vid} type="submit">Submit</Button>

                      <Spinner id={"spinner" + val.vid} animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                      <Form.Text id={"formStatus" + val.vid} className={"text-muted formStatus "+statusClass}>Submitted Successfully</Form.Text>
                    </Form>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>




      </div>
    )
  }
}
export default Localservices;