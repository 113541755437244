import React from 'react';
import TopHeader from "../components/topHeader.js";
import "./subview.scss";

class meetingdetails extends React.Component {
  render() {
    return (
      <div className="societyBoard noticeBoard">
          <TopHeader heading="Meeting Details" routeTo="/society" />
          <div className="boardBlock topContSpace">
                    <div className="noticeContainer">
                        <h3>No latest Meeting update</h3></div>
      </div>
      </div>
    )
  }
}
export default meetingdetails;